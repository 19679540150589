import React from "react";
import styles from "./downloadable-content.module.scss";
import Download from "../../../assets/svg/Download.svg";

const DownloadItem = ({ description, url }) => {
  return (
    <div className={styles.section_row_downContainer}>
      <p className={styles.section_row_downContainer_description}>
        {description}
      </p>
      <div className={styles.icons}>
        <a
          style={{ color: "inherit", display: "flex", alignItems: "center" }}
          href={url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={Download} alt='Download Icon' loading="lazy"/> <span>Download Now</span>
        </a>        
      </div>
    </div>
  );
};

export default DownloadItem;
