import React from "react";
import styles from "./downloadable-content.module.scss";
import divider from "../../../assets/image/line6.png";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import DownloadItem from "./downloadable-content-item.js";
import { motion } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";

const DownloadableContent = ({ id, downloads }) => {
  const [downSection, downVisible] = useOnScreen({ threshold: 0.3 });
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={downVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ type: "spring", stiffness: 20 }}
      ref={downSection}
      className={styles.section}
      id={id}
    >
      <div className={styles.section_row}>
        <div className={styles.section_row_col}>
          <p className='subtitleblog'>{t("CONTENT.DOWNLOAD.TITLE")}</p>
          <p className='op7'>{t("CONTENT.DOWNLOAD.SUBTITLE")}</p>
          <div className='mb10'>
            <img src={divider} alt='divider' loading="lazy" style={{ maxWidth: "100%" }} />
          </div>
          <div className='row'>
            {downloads.map(el => (
              <DownloadItem
                key={el.id}
                url={el.download_translations[language]?.file?.url}
                description={el.download_translations[language]?.description}
              />
            ))}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default DownloadableContent;
